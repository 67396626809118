.error-msg-text{
    display: none;
}
.profile-page-input-error{
     .profile-page-input-error-field{
       border-color: #fda29b;
     }
     .error-msg-text{
       display: block;
     }
     .select__control {
       border-radius: $border-radius !important;
       border-color: #fda29b !important;
     }
}
.user-nav {
  border-bottom: 1px solid $gray-200;
  @include media-breakpoint-up(md) {
    border-bottom: 0;
  }
  .nav-link {
    color: $gray-500;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 2px solid transparent;
    &:hover {
      border-color: transparent;
    }
    @include media-breakpoint-up(md) {
      border-left: 2px solid transparent;
      border-bottom: 0;
      &.active {
        border-left: 2px solid $primary;
        border-bottom: 0 !important;
      }
    }
    &.active {
      color: $primary;
      background-color: #f9f5ff;
      border-bottom: 2px solid $primary;
    }
  }
}

input.social-input {
  width: 100%;
  box-sizing: border-box;
  background-position: 0.625rem 0.625rem;
  background-repeat: no-repeat;
  padding-left: 42px;
  &.linkedin {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='rgb(10,102,194)' class='bi bi-linkedin' viewBox='0 0 16 16'><path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z'/></svg>");
  }
  &.github {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' class='bi bi-github' viewBox='0 0 16 16'><path d='M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z'/></svg>");
  }
  &.payment-card {
    padding-left: 52px;
    background-image: url("../../images/icons/payment-method2.svg");
  }
}

// recruiterProfile
.incompleteProfile {
  color: #b42318;
  background-color: #fef3f2;
  border-radius: $border-radius-xl;
}
.toaster {
  position: absolute;
  @include media-breakpoint-down(md) {
    position: relative;
  }
}
.recruiter-profile-content{
  padding-top: 85px;
  @include media-breakpoint-down(md){
    padding-top: 0px;
  }
  @include media-breakpoint-up(lg){
    padding-top:0px
  }
}
.nav-profile {
  margin-top: 110px;
  @include media-breakpoint-down(md) {
    margin-top: 0px;
  }
}
.profile-review {
  border-radius: $border-radius-xl;
  background-color: rgba(255, 250, 235, 1);
  color: rgba(181, 71, 8, 1);
}
.profile-review-border{
  border-bottom: 1px solid  #fef0c7;

}
.profile-reject {
  border-radius: $border-radius-xl;
  background-color: rgba(254, 243, 242, 1);
  color: rgba(180, 35, 24, 1);
}

.profile-reject-border{
  border-bottom: 1px solid  #fee4e2;

}
.defaultBtn {
  background-color: #f9f5ff;
  font-size: 0.75rem;
}

//candidatePublicView

.candidate-skill{
  background-color: #F2F4F7;
  font-weight: 500;
  color: #344054;
  mix-blend-mode: multiply;
}

// recruiterProfilePublicView

.company-banner {
  background-color: #717bbc;
}
.company-banner-2 {
  background-repeat: no-repeat;
  background-image: url("../../images/icons/rectangle.svg");
  @include media-breakpoint-down(md) {
    background-size: cover;
  }
}

.common-text{
  color: #101828;
}
.common-text-size{
  font-size: 1.25rem;
}

// candidate-profile-report

.candidate-banner {
  background-color: #7F56D9;
}
.candidate-banner-2 {
  background-repeat: no-repeat;
  background-image: url("../../images/icons/rectangle-purple.svg");
  @include media-breakpoint-down(md) {
    background-size: cover;
  }
}

.candidate-profile-report {
  resize: none;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  font-weight: 400;
  color: #101828;
}

.candidate-profile-report::first-line{
  text-transform: capitalize;
}

.candidate-profile-position{
  @include media-breakpoint-up(lg){
    position: absolute;
    right: 0;
    top: 0;
  }
}

.preserve-paragraph{
  white-space: pre-wrap;
}
