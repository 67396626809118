.loader {
  border: $loader-width solid #f3f3f3;
  border-radius: 50%;
  border-top: $loader-width solid $loader-color;
  width: 160px;
  height: 160px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: relative;
  &:before,
  &:after {
    content: "";
    width: $loader-width;
    height: $loader-width;
    border-radius: 50%;
    background: $loader-color;
    position: absolute;
  }

  &:before {
    top: 0.3rem;
    left: 0.3rem;
  }

  &:after {
    bottom: 6.7rem;
    left: 6.7rem;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
