// pagination
.activePage {
  color: #1d2939;
  background-color: #f9fafb;
  border-radius: 1.25rem;
}
.paginationBorder {
  border: none;
  @include media-breakpoint-down(md) {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.whiteBtn {
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #344054;
}
.whiteBtn:hover {
  color: #fff;
  border: 1px solid #344054;
  background-color: #344054;
}

.greenBtn {
  border-radius: 8px;
  border: 1px solid #12b76a;
  background: #12b76a;
  color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.greenBtn:hover{
  border: 1px solid #039855;
  background: #039855;
  color: #fff;
}