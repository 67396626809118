.payment-nav {
    border-bottom: 1px solid #98A2B3;
    .nav-link {
      color: $gray-500;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 2px solid transparent;
      &:hover {
        border-color: transparent;
      }
      &.active {
        color: $primary;
        background-color: #f9f5ff;
        border-bottom: 2px solid $primary;
      }
    }
  }