* {
  font-family: "Inter", sans-serif;
}

// .input-group {
//   border: $input-btn-border-width solid $input-border-color;
// }

.form-check-label {
  @extend .fw-medium;
}

.form-switch {
  .form-check-input {
    background-color: $gray-200;
    border: 0;
    &:checked {
      background-color: $primary;
    }
  }
}

.w-md-auto {
  @include media-breakpoint-up(md) {
    width: auto !important;
  }
}

.ng-select .ng-select-container,
.input-group-text,
.cash-input,
.form-control:not(.cash-input),
.form-select:not(.cash-input) {
  box-shadow: $box-shadow-xs;
}

.navbar-toggler-icon{
  width:1.5rem;
  height: 1.5rem;
}
