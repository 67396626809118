.btn-secondary-gray {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-semidark);
  --bs-btn-bg: var(--bs-white-bg);
  --bs-btn-border-color: var(--bs-gray-300);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-semidark);
  --bs-btn-hover-border-color: var(--bs-semidark);
  //   --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  //   --bs-btn-active-color: var(--bs-btn-hover-color);
  //   --bs-btn-active-bg: #{shade-color($bd-violet, 20%)};
  //   --bs-btn-active-border-color: #{shade-color($bd-violet, 20%)};
}
