.multi-range-slider-black {
  background-color: transparent !important;
  border: none !important;
  padding: 8px 4px 26px 0!important;
}

.multi-range-slider-black .thumb::before {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  border: 1px solid $primary-700 !important;
  margin: -7px -2px !important;
}

.multi-range-slider-black .caption {
  bottom: -22px !important;
}
.multi-range-slider-black .ruler {
  display: none !important;
}
.multi-range-slider-black .labels {
  display: none !important;
}
.multi-range-slider-black .bar-left {
  background-color: #eaecf0 !important;
  box-shadow: none !important;
  border: 0px !important;
}
.multi-range-slider-black .bar-right {
    background-color: #eaecf0 !important;
  box-shadow: none !important;
  border: 0px !important;
}
.multi-range-slider-black .bar-inner{
    background-color: #7F56D9 !important;
    border: 0px !important;

        box-shadow: none !important;
        border: none !important;
}
.multi-range-slider-black .thumb .caption *{
    min-width: none !important;
    height: 0 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 0px !important;
    background-color: transparent !important;
    color: #101828 !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin-left: 14px !important;
}
.multi-range-slider-black .thumb {
  padding-right: 16px  !important;
}