.multiple-skill-error {
  display: none;
}

.dropdown-toggle::after {
  display: none;
}

.profile_dropdown {
  border-radius: 23px;
}

.profile_dropdown {
  background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
  border-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
  padding: 0;
}


.profile_dropdown:hover, .dropdown .btn.show {
  color: var(--bs-btn-hover-color);
  background-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
  border-color: rgba(var(--bs-theme-rgb), var(--bs-bg-opacity)) !important;
}


.profile_dropdown .dropdown-menu {
  border-radius: 15px;
}

.spinner {
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.steps {
  display: flex;
  color: #fff;
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.steps-complete,
  &.steps-current {
    background-color: $primary;
  }

  &.steps-incomplete {
    background-color: #f2f4f7;
  }

  &.steps-current,
  &.steps-incomplete {
    .steps-inner {
      width: 10px;
      height: 10px;
    }

    .steps-check {
      display: none;
    }
  }
}

.progress-line {
  width: calc(50% - 3rem);
  height: 2px;
  background-color: #eaecf0;
}

.steps-complete:nth-of-type(1) + .progress-line,
.steps-complete:nth-of-type(2) + .progress-line {
  background-color: $primary;
}

.mobile-carousel {
  display: block;
  width: 100%;
  height: 400px;
  background: white;
  overflow-x: scroll;
  padding: 10px;
  margin: 0;
  white-space: nowrap;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.carousel {
  display: block;
  width: 100%;
  height: 400px;
  background: white;
  overflow-x: scroll;
  padding: 10px;
  margin: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);

  .card {
    width: 200px;
  }
}

input.search-input {
  width: 100%;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z' stroke='rgb(102,112,133)' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-position: 0.875rem 0.75rem;
  background-repeat: no-repeat;
  padding-left: 42px;
}

.input-group-text.cash-input {
  border-width: 1px;

  &:first-child {
    border-right: 0;
  }

  &:last-child {
    border-left: 0;
  }
}

.form-control.cash-input {
  border-width: 1px;
  border-right: 0;
  border-left: 0;
}

.blog-carousel {
  display: grid;
  grid-auto-columns: calc(100% - 4rem);
  grid-auto-flow: column;
  grid-gap: 1.5rem;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2rem;
  }
}

.home_dropdown{
  height: 200px;
}