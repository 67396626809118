// .guZdik {
//   position: relative;
//   display: flex;
//   flex-direction: column !important;
//   -webkit-box-align: center;
//   justify-content: center !important;
//   text-align: center !important;
//   min-width: 322px;
//   max-width: 508px;
//   height: 100% !important;
//   border: 0 !important;
//   padding: 8px 16px 8px 8px;
//   border-radius: 5px;
//   cursor: pointer;
//   -webkit-box-flex: 0;
//   flex-grow: 0;
//   padding-top: 16px !important;
//   @include media-breakpoint-up(md) {
//     padding-top: 92px !important;
//   }
// }
// .jWkLDY {
//   display: inline-block !important;
//   -webkit-box-pack: justify;
//   justify-content: space-between;
//   -webkit-box-flex: 1;
//   flex-grow: 1;
// }
// .kFhUBM {
//   font-size: 0.875rem !important;
//   & span {
//     color: rgb(105, 65, 198) !important;
//     font-weight: 600 !important;
//     text-decoration: none !important;
//   }
// }

.cv-upload {
    label {
      width: 100% !important;
      height: 100% !important;
    }
  }