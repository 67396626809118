.success-box {
  border-radius: 12px;
  border: 1px solid #6ce9a6;
  background: #f6fef9;
}
.success-text {
  color: #027a48;
  font-size: 14px;
  font-weight: 600;
}
.cursor{
    cursor: pointer;

}

