.chat-text-area {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  @include media-breakpoint-down(md) {
    border-radius: none;
    border: none;
    background: none;
    box-shadow: none;
  }
}
.chat-text-area-sm{
  @include media-breakpoint-down(md){
    padding: 12px;
    border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.unread-text {
  border-bottom: 1px solid #eaecf0;
  background: #f4ebff;
}
.selected-text {
  background-color: #f2f4f7;
}
.text-area {
  resize: none;
  outline: none;
}

.chat {
  position: relative;
  .chat-header {
    position: relative;
    height: 132px;
    z-index: 9;
    border-bottom: 1px solid rgb(225, 225, 227);
    background: rgb(255, 255, 255);
    @include media-breakpoint-up(lg) {
      height: 85px;
    }
  }
}
.chat-user-height {
  height: 78vh;
  max-height: 78vh;
  @include media-breakpoint-down(md){
    height: 72vh;
    max-height: 72vh;
  }
}
.chat-tab {
  height: 62.5vh;
  max-height: 62.5vh;
  overflow-y: scroll;
}
.end-text {
  display: flex;
  align-items: end;
}

.card {
  border-radius: 0 !important;
  box-shadow: none !important;
  height: 100% !important;
}
.badge-coustom {
  color: #6941c6;
  background-color: #f9f5ff;
  padding: 2px 8px;
  border-radius: 50%;
}

.fs-12 {
  font-size: 12px;
}
.text-gray-light {
  color: #667085;
}

.green-badge {
  border-radius: 8px;
  border: 1px solid #ecfdf3;
  background: #ecfdf3;
}
.green-badge:hover {
  border-radius: 8px;
  border: 1px solid #ecfdf3;
  background: #ecfdf3;
}

.chat-user-scroll {
  height: 80vh;
  max-height: 80vh;
  overflow-y: auto;
}
.chat-inbox {
  height: 78vh;
  max-height: 78vh;
  overflow-y: auto;
}

.chat .chat-item {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e4e6ef;
}

.fs-13 {
  font-size: 13px !important;
}

.horizontal-margin-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.loader-animate3 {
  background-image: url(https://user-images.githubusercontent.com/35243461/168796900-207c2e2a-4a21-4ef0-9dc7-fd82e3e20073.svg);
}

.svg36 {
  height: 36px;
  width: 36px;
}

.letter-space {
  letter-spacing: 1.5px;
}

.chat.chat-panel .left-chat-message,
.chat.chat-panel .right-chat-message {
  padding: 0.5rem 1rem;
  max-width: 100%;
}

.left-chat-message {
  position: relative;
  margin: 0 0 0 10px;
  background: #f2f4f7;
  color: #101828;
  width: auto;
  padding: 0.7rem 1rem;
  border-radius: 0 8px 8px 8px;
  -webkit-box-shadow: 0 4px 8px 0 rgb(34 41 47 / 3%);
  box-shadow: 0 4px 8px 0 rgb(34 41 47 / 3%);
}

.chat.chat-panel .message-arrow,
.chat.chat-panel .message-time {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 6px;
  font-size: 11px;
  color: #6c757d;
  cursor: pointer;
}

.chat.chat-panel .message-arrow,
.chat.chat-panel .message-time {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 6px;
  font-size: 11px;
  color: #6c757d;
  cursor: pointer;
}

.chat.chat-panel {
  background: #f9fafb;
  // background-color: #6941c6;/
}

.chat {
  position: relative;
}
.chat-box {
  max-width: 70%;
  min-width: 25%;
}

.chat.chat-panel .left-chat-message,
.chat.chat-panel .right-chat-message {
  padding: 0.5rem 1rem;
  max-width: 100%;
}

.chat.chat-panel .left-chat-message,
.chat.chat-panel .right-chat-message {
  padding: 0.5rem 1rem;
  max-width: 100%;
}

.right-chat-message {
  position: relative;
  margin: 0 0 0 0;
  background: #667085;
  color: #fff;
  width: auto;
  padding: 0.7rem 1rem;
  border-radius: 8px 0px 8px 8px;
  -webkit-box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
  box-shadow: 0 4px 8px 0 rgb(34 41 47 / 12%);
}

.chat.chat-panel .chat-upload-trigger {
  position: relative;
}

.chat.chat-panel .chat-upload.active {
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
  transform: scale(1);
  bottom: 50px;
}

.chat.chat-panel .chat-upload {
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
  transform: scale(0);
  position: absolute;
  left: -5px;
  bottom: -50px;
}

.chat-search .input-group-text i {
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
  cursor: pointer;
}

.chat-search .input-group-text i:hover {
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
  -webkit-transform: translateY(-2px) scale(1);
  transform: translateY(-2px) scale(1);
}

.chat.chat-panel .left-chat-message,
.chat.chat-panel .right-chat-message {
  padding: 0.5rem 1rem;
  max-width: 100%;
}

// .chat.chat-panel .message-arrow {
//   transform: scale(0);
// }

// .chat.chat-panel .left-chat-message:hover .message-time, .chat.chat-panel .right-chat-message:hover .message-time {
//   -webkit-transition: all .25s ease 0s;
//   transition: all .25s ease 0s;
//   transform: scale(0);
// }

// .chat.chat-panel .left-chat-message:hover .message-arrow, .chat.chat-panel .right-chat-message:hover .message-arrow {
//   -webkit-transition: all .25s ease 0s;
//   transition: all .25s ease 0s;
//   transform: scale(1);
// }

// .chat .chat-user-detail {
//   position: absolute;
//   left: 0;
//   width: 0;
//   opacity: 0;
//   z-index: -4;
// }

// .chat .chat-user-detail.active {
//   -webkit-transition: all .25s ease 0s;
//   transition: all .4s cubic-bezier(1, .04, 0, .93) 0s;
//   height: 100%;
//   width: 100%;
//   background: #f1f2fa;
//   z-index: 1;
//   opacity: 1;
// }

.card {
  margin-bottom: 0 !important;
  padding: 0 !important;
  border: none;
  box-shadow: 0 1px 2px #00000030;
  border-radius: 0.45rem;
  width: 100%;
}

input.chat-input {
  width: 100%;
  box-sizing: border-box;
  background-position: 0.625rem 0.625rem;
  background-repeat: no-repeat;
  padding-left: 40px;
  &.search {
    background-image: url("../../images/icons/search-lg.svg");
  }
}
