// .select__control {
//   min-height: 0 !important ;
//   outline: 0 !important;
//   position: relative;
//   -webkit-transition: all 100ms;
//   transition: all 100ms;
//   background-color: hsl(0, 0%, 100%);
//   border-color: $gray-300;
//   border-radius: $border-radius !important;
//   border-style: solid;
//   border-width: 1px;
//   box-sizing: border-box;
// }
// .select__value-container {
//   padding: 0.625rem 0.875rem !important;
//   margin: 0 !important;
// }
// .select__placeholder {
// }
.select__control_error {
  .select__control {
    border-radius: $border-radius !important;
    border-color: red !important;
  }
  .multiple-skill-error{
    display: block;
    border-color: red !important;
  }
}
.select__control {
  border-radius: $border-radius !important;
  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }
  &.select__control--is-focused {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
    &:hover {
      border-color: $input-focus-border-color;
    }
    &.select__control--menu-is-open {
      min-height: 0 !important;
      outline: 0 !important;
      position: relative;
      -webkit-transition: all 100ms;
      transition: all 100ms;
      background-color: hsl(0, 0%, 100%);
      border: 0 !important;
      border-color: $border-radius;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      box-shadow: $input-focus-box-shadow;
      box-sizing: border-box;
    }
  }
}
.select__value-container {
  padding: 0.625rem 0 0.625rem 0.875rem !important;
  margin: 0 !important;
}
.select__placeholder {
  grid-area: 1/1/2/3;
  color: $gray-500 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  box-sizing: border-box;
}
.select__single-value {
  grid-area: 1/1/2/3;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $gray-900 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  // margin-bottom: 4px !important;
  box-sizing: border-box;
}
​
.select__indicator {
  &.select__dropdown-indicator {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    color: hsl(0, 0%, 80%);
    padding: 8px 12px 8px 8px !important;
    box-sizing: border-box;
  }
}
​
.select__menu {
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  border-radius: $border-radius !important;
  box-shadow: $box-shadow;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  box-sizing: border-box;
  padding: 6px !important;
}
.select__menu--list {
  max-height: 0 !important;
  overflow-y: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
​
.select__option {
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: transparent;
  color: $gray-900 !important;
  padding: 0 !important;
  padding: 10px 0 10px 8px !important;
  margin-bottom: 4px !important;
  box-sizing: border-box;
}
​
.select__option--is-selected {
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: $gray-100 !important;
  color: $gray-900 !important;
  // padding: 10px 8px !important;
  border-radius: 6px !important;
  box-sizing: border-box;
}
​
.select__option--is-disabled {
  &.select__option--is-selected {
    cursor: default;
    display: block;
    font-size: inherit;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: $gray-100 !important;
    color: $gray-900 !important;
    // padding: 10px 8px !important;
    border-radius: 6px !important;
    box-sizing: border-box;
  }
}
.select__option--is-focused {
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: $gray-50 !important;
  color: inherit;
  color: $gray-900 !important;
  // padding: 10px 8px !important;
  border-radius: $border-radius !important;
  box-sizing: border-box;
}
.select__option--is-disabled {
  cursor: default;
  display: block;
  font-size: inherit;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: $gray-200 !important;
  color: $gray-500 !important;
  // padding: 8px 12px;
  border-radius: $border-radius !important;
  box-sizing: border-box;
}
.css-1nmdiq5-menu{
  z-index: 5 !important;
}