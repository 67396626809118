.terms-conditions-privacy-holder {
  counter-reset: section;
}

.main-section {
  counter-reset: sub-section;
}

.main-section::before {
  counter-increment: section;
  content: counter(section) ". ";
  margin-right: 0.25rem;
}

.sub-section::before {
  counter-increment: sub-section;
  content: counter(section) "." counter(sub-section) " ";
  margin-right: 0.25rem;
}
