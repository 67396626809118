.error-input {
  border-color: #fda29b;
}
.error-salary-input {
  // border-color: #fda29b;
}

.error-salary {
  .error-salary-input {
    border-color: #fda29b;
  }
}

.error-text {
  color: #b42318;
}

.error-text-light {
  color: #d92d20;
}
.deleteError {
  background-image: url("../../images/icons/error-delete.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
  &:hover {
    background-image: url("../../images/icons/error-delete-hover.svg");
  }
}
.deletebtn {
  background-image: url("../../images/icons/delete-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
  &:hover {
    background-image: url("../../images/icons/delete-icon-hover.svg");
  }
}

.deleteBtnDisabled{
  --bs-btn-border-width: 0px
}
.deleteCross {
  background-image: url("../../images/icons/x-close-red.svg");
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
  &:hover {
    background-image: url("../../images/icons/x-close-red-hover.svg");
  }
}
