// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-50: #f9fafb !default;
$gray-100: #f2f4f7 !default;
$gray-200: #eaecf0 !default;
$gray-300: #d0d5dd !default;
$gray-400: #98a2b3 !default;
$gray-500: #667085 !default;
$gray-600: #475467 !default;
$gray-700: #344054 !default;
$gray-800: #1d2939 !default;
$gray-900: #101828 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "50": $gray-50,
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #4361ee !default;
$blue-50: #f9f5ff !default;
$indigo: #7f56d9 !default;
$indigo-700: #6941c6 !default;
$pink: #d63384 !default;
$red: #b42318 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #29bf12 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "blue-50": $blue-50,
  "indigo": $indigo,
  "indigo-700": $indigo-700,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "black": $black,
  "white": $white,
  "gray": $gray-600,
  "gray-light": $gray-500,
  "semidark": $gray-700,
  "gray-dark": $gray-800,
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $indigo !default;
$primary-700: $indigo-700 !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-50 !default;
$dark: $gray-900 !default;
$semidark: $gray-700 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "theme": $primary-700,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "semidark": $semidark,
) !default;
// scss-docs-end theme-colors-map

$body-color: $gray-600 !default;
$link-color: $primary-700 !default;
$link-decoration: none !default;

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer * 0.75,
  4: $spacer,
  5: $spacer * 1.25,
  6: $spacer * 1.5,
  7: $spacer * 2,
) !default;
// scss-docs-end spacer-variables-maps

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.125 !default;

$font-weight-semibold: 600 !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 1.75 !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2.25 !default;
$h3-font-size: $font-size-base * 2 !default;
$h4-font-size: $font-size-base * 1.75 !default;
$h5-font-size: $font-size-base * 1.5 !default;
$h6-font-size: $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: 0 !default;
$headings-font-weight: 600 !default;
$headings-color: $dark !default;
$headings-line-height: 1.25 !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 4.5rem,
  2: 3.75rem,
  3: 3rem,
  4: 2.25rem,
  5: 1.875rem,
  6: 1.5rem,
) !default;

$display-font-family: null !default;
$display-font-style: null !default;
$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start border-radius-variables
$border-radius: 0.5rem !default;
$border-radius-sm: 0.375rem !default;
$border-radius-lg: 0.75rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 1.5rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-end headings-variables

// scss-docs-start form-label-variables
$forlabel-margin-bottom: 0.375rem !default;
$form-label-color: $gray-700 !default;
$form-label-font-size: $font-size-sm !default;
$form-label-font-weight: $font-weight-medium !default;
$form-select-color: $gray-500 !default;
$form-select-indicator-color: $gray-500 !default;
$form-check-label-color: $gray-700 !default;
$form-check-margin-bottom: 0 !default;
// scss-docs-start form-label-variables

// scss-docs-start form-text-variables
$form-text-margin-top: 0.375rem !default;
$form-text-color: $gray-600 !default;
// scss-docs-end form-text-variables

// scss-docs-start form-check-variables
// $form-check-input-checked-color: $component-active-color !default;
$form-check-input-width: 1.25em !default;
$form-check-input-border-radius: $border-radius-sm !default;
$form-check-padding-start: $form-check-input-width + 0.75em !default;
$form-check-input-checked-bg-color: $blue-50 !default;
$form-check-input-checked-border-color: $primary !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236941c6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e") !default;
// $form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color: $white !default;
$form-switch-width: 2.25em !default;
$form-switch-checked-color: $white !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgb(255,255,255)'/></svg>") !default;
// scss-docs-start form-switch-variables

// scss-docs-start form-input-variables
$input-font-size: $font-size-base !default;
// scss-docs-end form-input-variables

$loader-width: 1rem;
$loader-color: $primary;

$card-border-radius: $border-radius-lg !default;
$card-title-color: $dark !default;
$card-spacer-y: $spacer * 1.5 !default;
$card-spacer-x: $spacer * 2 !default;

$box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
  0px 2px 4px -2px rgba(16, 24, 40, 0.06) !default;
$box-shadow-sm: 0px 1px 3px rgba(16, 24, 40, 0.1),
  0px 1px 2px rgba(16, 24, 40, 0.06) !default;

$box-shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05) !default;
// Badges

// scss-docs-start badge-variables
$badge-font-size: 1em !default;
$badge-font-weight: $font-weight-semibold !default;
$badge-color: $primary-700 !default;
$badge-padding-y: 0.25rem !default;
$badge-padding-x: 0.75rem !default;
// scss-docs-end badge-variables

// scss-docs-start form-input-variables
$input-placeholder-color: $gray-500 !default;
$input-group-addon-bg: $white !default;
// scss-docs-start form-input-variables

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.625rem !default;
$input-btn-padding-x: 0.875rem !default;
$input-btn-font-size: $font-size-base !default;
$input-font-size: $input-btn-font-size !default;
$input-color: $gray-900 !default;

$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 0.875rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
// scss-docs-start input-btn-variables

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-width: 1px !default;
$btn-font-weight: $font-weight-semibold !default;
$btn-box-shadow: $box-shadow-xs !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;
$btn-border-radius-sm: $border-radius !default;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link

$navbar-toggler-padding-y: 1rem !default;
$navbar-toggler-padding-x: 1rem !default;
$navbar-toggler-border-radius: $btn-border-radius !default;
$navbar-toggler-focus-width: 0 !default;
$navbar-toggler-transition: 0 !default;

$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-justify-right' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z'/></svg>") !default;
$navbar-light-toggler-border-color: 0 !default;

// scss-docs-end navbar-variables
// scss-docs-start modal-variables

$modal-md: 37rem;

$modal-header-padding: 1.5rem 1.5rem;

// scss-docs-end modal-variables

// scss-docs-start progressbar-variables
$progress-height:  0.5rem;
// modal
$modal-header-border-color: none;

// scss-docs-start nav-tabs
$nav-link-padding-y: 0.75rem;
$nav-link-padding-x: 1.5rem;
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: $gray-700;

$nav-tabs-border-width: 0;
$nav-tabs-link-active-color: white;
$nav-tabs-link-active-bg: $primary-700;

// scss-docs-end nav-tabs
